import React from 'react';
import { IonCard, IonCardContent, IonPage, IonRow, IonText } from '@ionic/react';


const ShipAndPay = () => {
    return (
        <IonPage style={{ background: `#e6ffe6` }}>
            <IonCard className="ion-padding" style={{ overflow: `auto` }}>
                <IonCardContent>
                    <h1> Shipping and Payment Information</h1>
                    <br />
                    <h2><IonText color="success"><b><u>Shipping</u></b></IonText></h2>
                    <p>Sorry we dont offer shipping of products at the moment.<br/>For special delivaries please contact/message 0434153151.</p>
                    <br />
                    <h2><IonText color="success"><b><u>Payment Options</u></b></IonText></h2>
                    <p>Please place your order first and wait for the order confirmation email before making payment.<br /> You will receive all the payment details in the confirmation email.<br /><b>Please use the order id or your name as referrence when making the payment.</b> </p><br/>
                    <IonRow><h2><b>Bank Pay ID Email :</b> &nbsp;littlefarms.australia@gmail.com</h2></IonRow>
                    <IonRow><h2><b>Bank Pay ID Mobile :</b> &nbsp;0434153151</h2></IonRow>
                    <IonRow><h2><b>Bank Transfer :</b> <br /> &nbsp;&nbsp;&nbsp;<IonText color="primary">BSB : </IonText>062247<br />&nbsp;&nbsp;&nbsp;<IonText color="primary">Account : </IonText>10273583</h2></IonRow>
                    <br/><b>You can also pay in cash or card at pickup time.</b>
                </IonCardContent>
            </IonCard>
        </IonPage>
    );
}

export default ShipAndPay;