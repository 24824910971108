import {
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonImg,
  IonRouterLink,
  IonRow,
  IonLabel,
  IonIcon,
  IonButton,
  IonInput,
  IonItem,
  IonPage,
  IonText,
  useIonAlert,
} from "@ionic/react";
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { removeCircleOutline, addCircleOutline } from "ionicons/icons";
import ReCAPTCHA from "react-google-recaptcha";
import { CartContext, CartContextType } from "../store/CartContext";
import emailjs from '@emailjs/browser'

interface NavigationLink {
  name: string;
  value: string;
}

const Product: React.FC = () => {
  const RECAPTCHA_KEY = '6LfUfh8gAAAAAD4yPoUI2dZcgwqmGGjEnffd4wPP'
  const recaptchaRef = React.useRef(null);
  const { id } = useParams<{ id: string }>();

  const [title, setTitle] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [scientific, setScientific] = useState<string>("");
  const [nav, setNav] = useState<NavigationLink[]>([])
  const [max_quantity, setMaxQuantity] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(1);
  const [content, setContent] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [ship, setShip] = useState<string>("");
  const [disableAdd, setDisableAdd] = useState<boolean>(false);
  const [disableSub, setDisableSub] = useState<boolean>(true);
  const [capthaSize, setCapthaSize] = React.useState<string>(
    window.innerWidth >= 992 ? "normal" : "compact"
  );
  const [inputSize, setInputSize] = React.useState<number>(
    window.innerWidth >= 992 ? 80 : 100
  );
  const [disableCaptha, setDisableCaptha] = React.useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [disableSubmitEmail, setDisableSubmitEmail] = React.useState<boolean>(true);
  const { cart, addToCart } = useContext(CartContext) as CartContextType;
  const [present] = useIonAlert();

  useEffect(() => {
    let apiName = "info/" + id + ".json";
    fetch(apiName)
      .then((response) => response.json())
      .then((data) => {
        setTitle(data.title);
        setImage(data.image);
        setDescription(data.desc);
        if (data.hasOwnProperty('sci')) {
          setScientific(data.sci);
        }
        setNav(data.nav);
        setMaxQuantity(data.max);
        setContent(data.content);
        setPrice(data.price);
        setShip(data.ship);
      });
  }, [id]);

  const getDisableSubmitButton = () => {
    if (!disableSubmitEmail && !disableCaptha) {
      return false;
    }
    return true;
  }

  const showCaptha = () => {
    if (capthaSize === "normal") {
      return (<ReCAPTCHA
        ref={recaptchaRef}
        sitekey={RECAPTCHA_KEY}
        size="normal"
        id="recaptcha-google"
        onChange={(e) => { setDisableCaptha(false) }} />)
    }
    else {
      return (<ReCAPTCHA
        ref={recaptchaRef}
        sitekey={RECAPTCHA_KEY}
        size="compact"
        id="recaptcha-google"
        onChange={(e) => { setDisableCaptha(false) }} />)
    }
  }

  React.useEffect(
    function setupListener() {
      function handleResize() {
        setCapthaSize(window.innerWidth >= 992 ? "normal" : "compact")
        setInputSize(window.innerWidth >= 992 ? 80 : 100)
      }
      window.addEventListener("resize", handleResize)

      return function cleanupListener() {
        window.removeEventListener("resize", handleResize)
      }
    }
  )

  const showNavigations = () => {
    let navLinks: any = [];
    for (let i in nav) {
      if (i !== "0") {
        navLinks.push(<>&nbsp; {"/"} &nbsp;</>);
      }
      navLinks.push(
        <IonRouterLink routerLink={nav[i].value}>{nav[i].name}</IonRouterLink>
      );
    }
    return (navLinks);
  }

  const handleSubmit = (e: React.FormEvent<HTMLIonButtonElement>) => {
    var formData = {
      id: id,
      title: title,
      email: email
    };
    if (recaptchaRef && recaptchaRef.current) {
    e.preventDefault();
    emailjs.send('littlefarms', 'template_vzv32ml', formData, 'xo1jQ3_mheF7K0TWr')
      .then((result) => present({
        header: 'Thankyou',
        message: 'We will notify you as soon as it is available',
        buttons: [{ text: 'Close' }],
        onDidDismiss: () => { window.location.reload(); }
      }), (error) => { alert("Please contact directly on +61-434153151.\nOR\nOver email littlefarms.australia@gmail.com") });
    }
  };

  const quantityToglesAdd = () => {
    let newQunatity = quantity + 1;
    setQuantity(newQunatity);
    if (newQunatity === max_quantity) {
      setDisableAdd(true);
    }
    else {
      setDisableAdd(false);
    }

    if (newQunatity > 1) {
      setDisableSub(false);
    }
    else {
      setDisableSub(true);
    }
  }

  const quantityToglesSub = () => {
    let newQuantity = quantity - 1;
    setQuantity(newQuantity);
    if (newQuantity <= 1) {
      setDisableSub(true);
    }
    else {
      setDisableSub(false);
    }

    if (newQuantity < max_quantity) {
      setDisableAdd(false);
    }
    else {
      setDisableAdd(true);
    }
  }

  const showShoppingOptions = () => {
    if (max_quantity > 0) {
      return (
        <React.Fragment>
          <IonRow className="ion-align-items-center">
            <IonLabel color="dark" style={{ fontSize: "1.1em" }}>
              <strong>Quantity</strong>
            </IonLabel>
            <IonButton
              size="small"
              fill="clear"
              shape="round"
              color="medium"
              className="plusminus"
              disabled={disableAdd}
              onClick={() => quantityToglesAdd()}
            >
              <IonIcon icon={addCircleOutline} />
            </IonButton>
            <IonLabel>{quantity}</IonLabel>
            <IonButton
              size="small"
              fill="clear"
              shape="round"
              color="medium"
              className="plusminus"
              disabled={disableSub}
              onClick={() => quantityToglesSub()}>
              <IonIcon icon={removeCircleOutline} />
            </IonButton>
          </IonRow>
          <br />
          <IonRow>
            <IonButton
              fill="outline"
              shape="round"
              color="medium"
              className="addtocart"
              onClick={() => {
                addToCart({ id: id, quantity: quantity, title: title, image: image, price: price, content: content, ship: ship })
                localStorage.setItem("cart", JSON.stringify(cart))
              }}
            >
              ADD TO CART
            </IonButton>
          </IonRow>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <IonRow>
            <br />
            <b>Out of Stock</b>
          </IonRow>
          <IonRow>Notify me when available</IonRow>
          {showCaptha()}
          <IonRow>
            <IonItem
              style={{ borderStyle: "solid", borderColor: "lightgrey", borderWidth: "0.1em", width: `${inputSize}%`}}
            >
              <IonInput
                inputMode="email"
                placeholder="Insert your email"
                autocomplete="email"
                autofocus={true}
                required={true}
                type="email"
                value={email}
                onIonChange={(e) => {
                  setEmail(e.detail.value!);
                  setDisableSubmitEmail(false);
                }}
              ></IonInput>
              <IonButton
                fill="outline"
                shape="round"
                color="medium"
                type="submit"
                disabled={getDisableSubmitButton()}
                onClick={e => { handleSubmit(e) }}
              >
                Submit
              </IonButton>
            </IonItem>
          </IonRow>
        </React.Fragment>
      );
    }
  };

  const showContents = () => {
    if (!(content === "")) {
      return (
        <React.Fragment>
          <br />
          {content}
          <br />
        </React.Fragment>
      );
    }
    return ("");
  }

  const showPrice = () => {
    if (typeof price !== "undefined") {
      return (
        <React.Fragment>
          <br />
          <IonLabel style={{ fontSize: "1.25em" }}>
            <b>${price}</b>
          </IonLabel>
        </React.Fragment>
      );
    }
    return ("");
  }

  const shipping = () => {
    if (ship === "Free") {
      return (<IonText color="success">Free Shipping</IonText>);
    }
    else if (ship === "Ed") {
      return (<IonText color="tertiary">Pickup Edmondson Park</IonText>);
    }
    else {
      return (<IonText color="warning">Shipping : {ship}</IonText>);
    }
  }

  const showShipping = () => {
    if (!(ship === "")) {
      return (
        <React.Fragment>
          {shipping()}
        </React.Fragment>
      );
    }
    return ("");
  }

  const showWarning = () => {
    let isSeed: boolean = false;
    for (let i in nav) {
      if (nav[i].name === "Seed") {
        isSeed = true;
      }
    }
    if (isSeed) {
      return (<React.Fragment>
        <IonLabel style={{ "font-weight": "bold" }} color="danger">Sale only in Australia(Excluding TAS and WA)</IonLabel>
      </React.Fragment>)
    }
    return ("");
  }

  return (
    <IonPage style={{ background: `#e6ffe6` }}>
      <div style={{ overflow: `auto` }}>
        <IonCard>
          <IonCardContent>
            <IonGrid style={{ border: `none` }}>
              <IonRow className="ion-padding-start">
                {" "}
                <b>
                  {showNavigations()}
                </b>
              </IonRow>
              <IonRow>
                <IonCol
                  size-xs="12"
                  size-md="6"
                  className="ion-align-self-start"
                >
                  <IonImg src={image} />
                </IonCol>
                <IonCol>
                  <IonCardSubtitle>
                    <i>{scientific}</i>
                  </IonCardSubtitle>
                  <br />
                  <IonCardTitle>{title}</IonCardTitle>
                  {showPrice()}
                  <br />
                  {showShipping()}
                  <br />
                  {showContents()}
                  <br />
                  {description}
                  <br />
                  <br />
                  {showWarning()}
                  {showShoppingOptions()}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </div>
    </IonPage>
  );
};

export default Product;
